import { render, staticRenderFns } from "./newmobile.vue?vue&type=template&id=91a0e744&scoped=true&"
import script from "./newmobile.vue?vue&type=script&lang=js&"
export * from "./newmobile.vue?vue&type=script&lang=js&"
import style0 from "./newmobile.vue?vue&type=style&index=0&id=91a0e744&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a0e744",
  null
  
)

export default component.exports